import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clubs: [],
  totalClubs: 0,
  isLoading: true
};

export const clubSlice = createSlice({
  name: 'club',
  initialState,
  reducers: {
    clubListAction: (state, action) => {
      // console.log(action.payload, 'acacpl')
      if (!state.clubs.length) {
        state.clubs = action.payload.clubs;
      } else {
        state.clubs = [...state.clubs, ...action.payload.clubs];
      }

      state.totalClubs = action.payload.totalClubs;
      state.isLoading = false

    },
    createClubAction: (state, action) => {
      state.clubs.unshift(action.payload);
      state.totalClubs += 1;
    },

    editClubAction: (state, action ) => {
      const index = state.clubs.findIndex((club) => club.id === action.payload.id);
      if (index !== -1) {
        state.clubs.splice(index, 1);
        state.totalClubs -= 1;
      } else {
        state.clubs.push(action.payload);
        state.totalClubs += 1;
      }
    },
    deleteClubAction: (state, action) => {
      const index = state.clubs.findIndex((club) => club.id === action.payload);
      state.clubs.splice(index, 1);
      state.isLoading = false

    },
    emptyListAction: (state) => {
      state.clubs = [];
      state.totalClubs = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { clubListAction, editClubAction, emptyListAction, deleteClubAction, createClubAction } =
  clubSlice.actions;

export const clubReducer = clubSlice.reducer;
