import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  faqs: [],
  totalFaqs: 0,
};

export const faqsSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    faqsListAction: (state, action) => {
      // console.log(action.payload, 'acacpl')
      if (!state.faqs.length) {
        state.faqs = action.payload.faqs;
      } else {
        state.faqs = [...state.faqs, ...action.payload.faqs];
      }

      state.totalFaqs = action.payload.totalFaqs;
    },
    editfaqsAction: (state, action ) => {
      const index = state.faqs.findIndex((club) => club.id === action.payload.id);
      if (index !== -1) {
        state.faqs.splice(index, 1);
        state.totalFaqs -= 1;
      } else {
        state.faqs.push(action.payload);
        state.totalFaqs += 1;
      }
    },
    deleteFaqsAction: (state, action) => {
      const index = state.faqs.findIndex((club) => club.id === action.payload);
      state.faqs.splice(index, 1);
    },
    emptyListAction: (state) => {
      state.faqs = [];
      state.totalFaqs = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { faqsListAction, editfaqsAction, emptyListAction, deleteFaqsAction } =
    faqsSlice.actions;

export const faqReducer = faqsSlice.reducer;
