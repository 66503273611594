import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  totalUsers: 0,
  isLoading: true
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userListAction: (state, action) => {
      // console.log(action.payload, 'acacpl')
      if (!state.users.length) {
        state.users = action.payload.users;
        
      } else {
        state.users = [...state.users, ...action.payload.users];
      }

      state.totalUsers = action.payload.totalUsers;
      state.isLoading = false

    },
    editUserAction: (state, action ) => {
      const index = state.users.findIndex((user) => user.id === action.payload.id);
      if (index !== -1) {
        state.users.splice(index, 1);
        state.totalUsers -= 1;
      } else {
        state.users.push(action.payload);
        state.totalUsers += 1;
      }
    },
    deleteUserAction: (state, action) => {
      const index = state.users.findIndex((user) => user.id === action.payload);
      state.users.splice(index, 1);
    },
    emptyListAction: (state) => {
      state.users = [];
      state.totalUsers = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userListAction, editUserAction, emptyListAction, deleteUserAction } =
  userSlice.actions;

export const userReducer = userSlice.reducer;
